import React from 'react'
import { Route } from 'react-router-dom'

import AsyncComponent from '~/components/AsyncComponent'

const Education = AsyncComponent({
  component: () => import('./IndexPage/EducationsContainer'),
})

const Course = AsyncComponent({
  component: () => import('./Course/CourseContainer'),
})

export const educationRoutes = (
  <>
    <Route exact index element={<Education key="index" />} />
    <Route exact path=":slug" element={<Course />} />
  </>
)
